export default function injectSardineScript(isSandbox, clientId, sessionKey, userIdHash) {
  if (document.getElementById("initSardine")) return

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.innerHTML = `
    var isSandbox = ${isSandbox};
    var sardineHost = isSandbox ? 'api.sandbox.sardine.ai' : 'api.sardine.ai';
    (function () {
        var loader = document.createElement('script');
        loader.id = 'initSardine';
        loader.type = 'text/javascript';
        loader.async = true;
        loader.src = \`https://\${sardineHost}/assets/loader.min.js\`;
        loader.onload = function() {
            sardineContext = window._Sardine.createContext({
                clientId: "${clientId}",
                sessionKey: "${sessionKey}",
                userIdHash: "${userIdHash}",
                flow: "onboarding",
                environment: isSandbox ? "sandbox" : "production",
                parentElement: document.body,
                onDeviceResponse: function(data) {}
            });
             sardineContext.getSardineSessionKey = function() {
                return "${sessionKey}";
            };
        };
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(loader, s);
    })();
  `;

  document.head.appendChild(script);
}
