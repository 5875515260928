import API from '@base/services/API'
import { useQuery } from 'react-query'

export function usePrizeDetails() {
  const { data = [], isLoading, error } = useQuery(['prizes-modal'], () => API.getPrizeDetails(), { staleTime: 5000 })

  return {
    data,
    isLoading,
    error
  }
}
