import { configureStore, combineReducers } from '@reduxjs/toolkit'
import * as _ from 'lodash-es'
import hotelBookingSlice from './slices/hotelBookingSlice'
import promoCodeIsOpenSlice from './slices/promoCodeIsOpenSlice'
import searchArgsSlice from './slices/searchArgsSlice'
import footerSlice from './slices/footerSlice'
import nearMeDataSlice from './slices/nearMeDataSlice'
import userSlice from './slices/userSlice'
import nearMePickedSlice from './slices/nearMePickedSlice'
import locationSearchSlice from './slices/locationSearchSlice'
import userDetailsSlice from './slices/userDetailsSlice'
import extrasSelectedSlice from './slices/extrasSelectedSlice'
import carRentalProductSlice from './slices/carRentalProductSlice'
import xo1DetailsSlice from './slices/xo1DetailsSlice'
import reservationResponseSlice from './slices/reservationResponseSlice'
import reservationInfoRetrieval from './slices/reservationInfoRetrievalSlice'
import optimizeSlice from './slices/optimizeSlice'
import carSearchFiltersSlice from './slices/carSearchFiltersSlice'
import filterTagsSlice from './slices/filterTagsSlice'
import sortingOptionSlice from './slices/sortingOptionSlice'
import threeDsSlice from './slices/threeDsSlice'
import bundleBookingSlice from './slices/bundleBookingSlice'
import skyscannerSlice from './slices/skyScannerSlice'
import util from '../util'
import ancillarySlice from './slices/ancillarySlice'
import modificationSlice from './slices/modificationSlice'
import carlaPlusSlice from './slices/carlaPlusSlice'

// an UUID
const STORAGE_KEY = 'fe2ff1a4-1ddd-4a94-bb31-9ff2289c74bf'

let storedState = null
try {
  if (typeof window !== 'undefined') storedState = JSON.parse(window?.localStorage.getItem(STORAGE_KEY))
} catch (error) {
  console.log(error)
}

export const rootReducer = combineReducers({
  hotelBooking: hotelBookingSlice,
  promoCodeIsOpen: promoCodeIsOpenSlice,
  searchArgs: searchArgsSlice,
  footer: footerSlice,
  nearMeData: nearMeDataSlice,
  user: userSlice,
  nearMePicked: nearMePickedSlice,
  locationSearch: locationSearchSlice,
  userDetails: userDetailsSlice,
  extrasSelected: extrasSelectedSlice,
  carRentalProduct: carRentalProductSlice,
  xo1Details: xo1DetailsSlice,
  reservationResponse: reservationResponseSlice,
  reservationInfoRetrieval,
  optimize: optimizeSlice,
  carSearchFilters: carSearchFiltersSlice,
  filterTags: filterTagsSlice,
  sortingOption: sortingOptionSlice,
  threeDs: threeDsSlice,
  bundleBooking: bundleBookingSlice,
  skyscanner: skyscannerSlice,
  ancillary: ancillarySlice,
  modification: modificationSlice,
  carlaPlus: carlaPlusSlice
})

const store = configureStore({
  reducer: rootReducer,
  middleware: () => [],
  preloadedState: _.isEmpty(storedState) ? undefined : storedState
  // devTools: window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
})

// Create a debounced version of the storage update function
const debouncedStorageUpdate = _.debounce(() => {
  const state = store.getState()

  const user = _.omit(state.user, ['credit', 'verified'])

  util.saveToLocalStorage(
    STORAGE_KEY,
    _.pick(state, [
      'searchArgs',
      'user',
      'userDetails',
      'carRentalProduct',
      'xo1Details',
      'reservationResponse',
      'hotelBooking',
      'bundleBooking',
      'locationSearch'
    ]),
    user // TODO: it should be store in 1 single store
  )
  // 86400000 = 1 day
  util.saveToLocalStorageWithExpiry('countryCodeExpiry', new Date().getTime(), 86400000)
}, 1000) // Adjust the debounce delay as needed

// Subscribe to store updates and call the debounced function
store.subscribe(debouncedStorageUpdate)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export { store }
