import Loadable from '@base/loadable'
import BurgerIcon from '@components/BurgerIcon'
import { FlexEl } from '@components/layout'
import useCheckCountry from '@hooks/useCheckCountry'
import { setPromoCodeOpen } from '@redux/slices'
import GA from '@services/GA'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import {
  LogoLink,
  MobileNavbar,
  MobileNavbarWrapper,
  NavLink,
  StyledMenu,
  MobileNavBarContainer
} from './../navigation.styles'
import { useHiddenByLang } from '@base/hooks/useHiddenByLang'

const BlogSearchButton = Loadable(React.lazy(() => import('./BlogSearchButton')))
const LanguageMenu = Loadable(React.lazy(() => import('@components/HomePage/LanguageMenu')))

function MobileNav() {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [isSkyscannerActive, setIsSkyscannerActive] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const location = useLocation()
  const currentPage = location

  // This is temporary. When full localization is achieved, this should be removed
  const isHiddenByLang = useHiddenByLang()
  const checkCountry = useCheckCountry()

  // TODO: make a hook
  useEffect(() => {
    const utmSource = Cookies.get('utm_source')
    if (
      utmSource === 'skyscanner' &&
      (currentPage.pathname === '/reservation' || currentPage.pathname.includes('/rental-cars/'))
    ) {
      setIsSkyscannerActive(true)
    }
  }, [currentPage.pathname])

  //eslint-disable-next-line
  let currentPath = location.pathname == '/' ? true : false

  const toggleMenu = () => {
    if (!menuIsOpen) {
      GA.openSidebar()
    }
    setMenuIsOpen(!menuIsOpen)
  }

  const closeMenu = () => setMenuIsOpen(false)

  const handleOpenMenu = () => {
    setMenuIsOpen(false)
    dispatch(setPromoCodeOpen(true))
  }
  let hidingLayout = false
  const hiddenHeaderPagePath = [
    '/payment',
    '/bundle-reservation',
    '/bundle-payment',
    '/hotel-reservation',
    '/hotel-payment',
    '/availableAreas'
  ]
  const hiddenHeaderPagePathForMobile = ['/rental-cars/', '/hotel-results/', '/hotel-bundle/list']

  const hiddenHeaderPagePathOnDesktop = ['/reservation', '/payment']

  if (hiddenHeaderPagePathOnDesktop.includes(currentPage.pathname) || currentPage.pathname.includes('/rental-cars/')) {
    hidingLayout = true
  }
  const isHiddenMobileHeader =
    hiddenHeaderPagePath.includes(currentPage.pathname) ||
    hiddenHeaderPagePathForMobile.some((path) => currentPage?.pathname.includes(path))

  if (isHiddenMobileHeader || (currentPage.pathname === '/reservation' && !isSkyscannerActive)) return null

  return (
    <header style={{ height: 50 }}>
      {/* MOBILE COLLAPSING MENU */}
      <StyledMenu
        right
        width='100%' // property for react-burger-menu (not styled)
        isOpen={menuIsOpen}
        pageWrapId='page-container'
        outerContainerId='root'
        customBurgerIcon={false}
        customCrossIcon={false}
        id='scrollableMenu'
      >
        <MobileNavBarContainer style={{ listStyle: 'none' }}>
          {!isHiddenByLang && (
            <li>
              <NavLink
                $isMobile
                onClick={closeMenu}
                className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}
                py={3}
                px={12}
                as={RouterLink}
                to='/'
              >
                Car
              </NavLink>
            </li>
          )}
          {!isHiddenByLang && (
            <li>
              <NavLink
                onClick={closeMenu}
                $isMobile
                className={`nav-link ${location.pathname === '/hotels' ? 'active' : ''}`}
                py={3}
                px={12}
                as={RouterLink}
                to='/hotels'
              >
                Hotel
              </NavLink>
            </li>
          )}
          {!isHiddenByLang && (
            <li>
              <NavLink
                onClick={closeMenu}
                $isMobile
                className={`nav-link ${location.pathname === '/hotel-bundle' ? 'active' : ''}`}
                py={3}
                px={12}
                as={RouterLink}
                to='/hotel-bundle'
              >
                Bundle & Save
              </NavLink>
            </li>
          )}
          <li>
            <NavLink
              $isMobile
              onClick={closeMenu}
              className={`nav-link ${location.pathname === '/my-reservations' ? 'active' : ''}`}
              py={3}
              px={12}
              as={RouterLink}
              to='/my-reservations'
            >
              {t('reservations:nav')}
            </NavLink>
          </li>
          <li>
            <NavLink
              $isMobile
              onClick={closeMenu}
              className={`nav-link ${location.pathname === '/contact' ? 'active' : ''}`}
              py={3}
              px={12}
              as={RouterLink}
              to='/contact'
            >
              {t('homePage:contactUs')}
            </NavLink>
          </li>
          <li>
            <NavLink
              $isMobile
              onClick={closeMenu}
              className={`nav-link ${location.pathname === '/blog/partnership-with-carla.html' ? 'active' : ''}`}
              py={3}
              px={12}
              as={RouterLink}
              to='/blog/partnership-with-carla.html'
            >
              {t('homePage:partnerWithUs')}
            </NavLink>
          </li>
          {currentPath && !isHiddenByLang && (
            <li>
              <NavLink
                $isMobile
                onClick={handleOpenMenu}
                className={`nav-link ${menuIsOpen ? 'active' : ''}`}
                py={3}
                mx={24}
              >
                Promo Code
              </NavLink>
            </li>
          )}
          <li style={{ visibility: !checkCountry ? 'hidden' : 'visible' }}>
            <NavLink
              $isMobile
              py={3}
              px={12}
              as='a'
              href='https://onelink.to/uhy54z'
              target='_blank'
              rel='noopener noreferrer'
              onClick={GA.clickDownloadApp}
            >
              {t('homePage:downloadApp')}
            </NavLink>
          </li>
        </MobileNavBarContainer>
      </StyledMenu>
      {/* FIXED NAVBAR */}
      <MobileNavbar id='mobile-nav' data-testid='mobile-nav'>
        <MobileNavbarWrapper>
          {/* LOGO */}
          <div style={{ minWidth: 66 }}>{!hidingLayout && <LanguageMenu textTransform='uppercase' />}</div>
          <LogoLink
            to='/'
            as={RouterLink}
            onClick={() => {
              closeMenu()
              GA.clickNavLogo()
            }}
          >
            <img src='/icons/logo-mobile.svg' alt='logo' />
          </LogoLink>
          {/* NAVBAR RIGHT */}
          {!hidingLayout && (
            <FlexEl id='toggle-button-wrapper' alignItems='center'>
              {/* DYNAMIC PART */}
              <BlogSearchButton />
              {/* BURGER */}
              <BurgerIcon isOpen={menuIsOpen} onClick={toggleMenu} />
            </FlexEl>
          )}
        </MobileNavbarWrapper>
      </MobileNavbar>
    </header>
  )
}

export default MobileNav
