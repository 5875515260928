import Loadable from '@base/loadable'
import { El, FlexEl } from '@components/layout'
import { setPromoCodeOpen } from '@redux/slices'
import GA from '@services/GA'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import {
  HeaderStyled,
  LogoLink,
  NavbarContainer,
  NavbarWrapper,
  NavLink,
  NavTagWrapper,
  UlTagWrapper
} from './../navigation.styles'
import DownloadAppLink from './DownloadAppLink'
import { useHiddenByLang } from '@base/hooks/useHiddenByLang'
import { usePined } from './usePinned'

const BlogSearchButton = Loadable(React.lazy(() => import('./BlogSearchButton')))
const LanguageMenu = Loadable(React.lazy(() => import('@components/HomePage/LanguageMenu')))

const PinnedNavByRoutes = ['/', '/hotels', '/hotel-bundle']

function Regular() {
  const [isSkyscannerActive, setIsSkyscannerActive] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const isHiddenByLang = useHiddenByLang()
  const pinned = usePined('#main-header')

  useEffect(() => {
    const handleAnchor = (e) => {
      e.preventDefault()

      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    }

    return () => {
      // UNKNOWN
      const Anchor = document.getElementById('SEECARRENTALPRICES')
      if (Anchor) {
        Anchor.removeEventListener('click', handleAnchor)
      }
    }
  }, [])

  // TODO: move to hook
  useEffect(() => {
    const utmSource = Cookies.get('utm_source')
    if (
      utmSource === 'skyscanner' &&
      (location.pathname === '/reservation' || location.pathname.includes('/rental-cars/'))
    ) {
      setIsSkyscannerActive(true)
    }
  }, [location.pathname])

  //eslint-disable-next-line
  let currentPath = location.pathname == '/' ? true : false
  const isPinned = !PinnedNavByRoutes.includes(location.pathname)

  const handleOpenMenu = () => {
    dispatch(setPromoCodeOpen(true))
  }

  let hidingLayout = false
  const hiddenHeaderPagePathOnDesktop = ['/reservation', '/payment']

  if (hiddenHeaderPagePathOnDesktop.includes(location.pathname) || location.pathname.includes('/rental-cars/')) {
    hidingLayout = true
  }

  const isBlogPage = location.pathname.startsWith('/blog')

  return (
    <HeaderStyled id='main-header' $isPinned={pinned || isPinned}>
      {/* TODO: Theme */}
      <NavbarContainer id='main-nav' style={{ margin: '0 auto' }}>
        <NavbarWrapper>
          {/* LOGO */}
          <LogoLink
            to='/'
            {...(!hidingLayout && { as: RouterLink })}
            {...(isSkyscannerActive && { as: RouterLink })}
            onClick={() => {
              if (!hidingLayout) GA.clickNavLogo()
            }}
            mr={3}
            style={{ cursor: isSkyscannerActive ? 'pointer' : hidingLayout ? 'initial' : 'pointer' }}
          >
            <img src='/icons/logo.svg' alt='logo' />
          </LogoLink>
          {/* DESKTOP LINKS */}
          {!hidingLayout && (
            <NavTagWrapper width={1} justifyContent='space-between' px={3}>
              <UlTagWrapper width={1} justifyContent='flex-end' alignItems='center' px={3}>
                {!isHiddenByLang && (
                  <li>
                    <NavLink
                      className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}
                      py={3}
                      mx={24}
                      as={RouterLink}
                      to='/'
                    >
                      Car
                    </NavLink>
                  </li>
                )}
                {!isHiddenByLang && (
                  <li>
                    <NavLink
                      className={`nav-link ${location.pathname === '/hotels' ? 'active' : ''}`}
                      py={3}
                      mx={24}
                      as={RouterLink}
                      to='/hotels'
                    >
                      Hotel
                    </NavLink>
                  </li>
                )}
                {!isHiddenByLang && (
                  <li>
                    <NavLink
                      className={`nav-link ${location.pathname === '/hotel-bundle' ? 'active' : ''}`}
                      py={3}
                      mx={24}
                      as={RouterLink}
                      to='/hotel-bundle'
                    >
                      Bundle & Save
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink
                    className={`nav-link ${location.pathname === '/my-reservations' ? 'active' : ''}`}
                    py={3}
                    mx={24}
                    as={RouterLink}
                    to='/my-reservations'
                  >
                    {t('reservations:nav')}
                  </NavLink>
                </li>
              </UlTagWrapper>
              <UlTagWrapper width={1} justifyContent='flex-end' alignItems='center' px={3}>
                <li>
                  <div style={{ margin: '0 24px' }}>
                    <LanguageMenu />
                  </div>
                </li>
                <li>
                  <NavLink
                    className={`nav-link ${location.pathname === '/contact' ? 'active' : ''}`}
                    py={3}
                    mx={24}
                    as={RouterLink}
                    to='/contact'
                  >
                    {t('homePage:contactUs')}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`nav-link ${location.pathname === '/blog/partnership-with-carla.html' ? 'active' : ''}`}
                    py={3}
                    mx={24}
                    as={RouterLink}
                    to='/blog/partnership-with-carla.html'
                  >
                    {t('homePage:partnerWithUs')}
                  </NavLink>
                </li>
                {currentPath && !isHiddenByLang && (
                  <li>
                    <NavLink onClick={handleOpenMenu} className={'nav-link'} py={3} mx={24}>
                      Promo Code
                    </NavLink>
                  </li>
                )}
                {/* DOWNLOAD APP */}
                <li>
                  <El style={{ position: 'relative' }}>
                    <DownloadAppLink />
                  </El>
                </li>
              </UlTagWrapper>
            </NavTagWrapper>
          )}
          {/* NAVBAR RIGHT */}
          {isBlogPage && (
            <FlexEl id='toggle-button-wrapper' alignItems='center'>
              {/* DYNAMIC PART */}
              <BlogSearchButton />
              {/* BURGER */}
            </FlexEl>
          )}
        </NavbarWrapper>
      </NavbarContainer>
    </HeaderStyled>
  )
}

export default Regular
