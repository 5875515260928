import { useState } from 'react'
import MobileNav from './Mobile'
import RegularNav from './Regular'
import { isMobile } from 'react-device-detect'
import { useEffect } from 'react'
import { useScreen } from '@components/common/Screens'

function MainNav() {
  const [mounted, setMounted] = useState(false)
  const { isDefaultScreen } = useScreen()

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) return <>{isMobile ? <MobileNav /> : <RegularNav />}</>
  return isDefaultScreen ? <MobileNav /> : <RegularNav />
}

export default MainNav
