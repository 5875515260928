export default function injectAffirmScript() {
  if (window.affirm) return

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.innerHTML = `
  var _affirm_config = {
    public_api_key: '${import.meta.env.VITE_AFFIRM_PUBLIC_KEY}',
    script: 'https://cdn1${import.meta.env.MODE === 'development' ? '-sandbox' : ''}.affirm.com/js/v2/affirm.js',
    locale: 'en_US',
    country_code: 'USA'
  }

  ;(function (m, g, n, d, a, e, h, c) {
    var b = m[n] || {},
      k = document.createElement(e),
      p = document.getElementsByTagName(e)[0],
      l = function (a, b, c) {
        return function () {
          a[b]._.push([c, arguments])
        }
      }
    b[d] = l(b, d, 'set')
    var f = b[d]
    b[a] = {}
    b[a]._ = []
    f._ = []
    b._ = []
    b[a][h] = l(b, a, h)
    b[c] = function () {
      b._.push([h, arguments])
    }
    a = 0
    for (c = 'set add save post open empty reset on off trigger ready setProduct'.split(' '); a < c.length; a++)
      f[c[a]] = l(b, d, c[a])
    a = 0
    for (c = ['get', 'token', 'url', 'items']; a < c.length; a++) f[c[a]] = function () {}
    k.async = !0
    k.src = g[e]
    p.parentNode.insertBefore(k, p)
    delete g[e]
    f(g)
    m[n] = b
  })(window, _affirm_config, 'affirm', 'checkout', 'ui', 'script', 'ready', 'jsReady')
  `


  document.head.appendChild(script);
}
