import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CarlaPlusState, CarlaPlusPrice } from '@components/CarlaPlus/types'

const initialState: CarlaPlusState = {
  isSelected: false,
  hasPurchased: false,
  price: { price: 0, currency: 'USD', iso4217Currency: 840 }
}

export const carlaPlusSlice = createSlice({
  name: 'carlaPlusSlice',
  initialState,
  reducers: {
    setIsSelected: (state, action: PayloadAction<boolean>) => {
      state.isSelected = action.payload
    },
    setHasPurchased: (state, action: PayloadAction<boolean>) => {
      state.hasPurchased = action.payload
    },
    setPrice: (state, action: PayloadAction<CarlaPlusPrice>) => {
      state.price = action.payload
    }
  }
})

export const { setIsSelected, setHasPurchased, setPrice } = carlaPlusSlice.actions

export default carlaPlusSlice.reducer
