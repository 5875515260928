import { Fragment, lazy } from 'react'
import { Helmet } from 'react-helmet'
import { HeaderTitle, SearchBarContainer } from './Home.styled'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useCheckCountry from '@hooks/useCheckCountry'
import FeatureCards from '@components/HomePage/FeatureCards'
import Loadable from '@base/loadable'
import { useHiddenByLang } from '@base/hooks/useHiddenByLang'
import { usePrizeDetails } from '@base/hooks/react-query/usePrizes'
import { DefaultScreen, DesktopScreen } from '@base/components/common/Screens'

const BlogSlide = Loadable(lazy(() => import('@components/HomePage/BlogSlide')))
const FAQsHomePage = Loadable(lazy(() => import('@components/HomePage/FAQsHomePage')))
const Press = Loadable(lazy(() => import('@components/HomePage/Press')))
const DownloadAppBanner = Loadable(lazy(() => import('@components/DownloadAppBanner')))
const Search = Loadable(lazy(() => import('@components/SearchBoxV3/CarSearch')))
const ThanksgivingModal = Loadable(lazy(() => import('@components/ThanksgivingModal/ThanksgivingModal')))
const ThanksgivingModalMobile = Loadable(
  lazy(() => import('@components/ThanksgivingModal/MobileVersion/MobileVersion'))
)
const ReactPortal = Loadable(lazy(() => import('@components/ReactPortal')))

const Home = ({ history }) => {
  const { t } = useTranslation()
  const isAllowedCountry = useCheckCountry()
  const useCountry = useSelector((state) => state.user.country) || 'US' // Default for US
  const hiddenByLang = useHiddenByLang()
  const { data: prizesData, error } = usePrizeDetails()
  const isShowWheel = !error && prizesData.prizes && prizesData.prizes.length

  return (
    <Fragment>
      <Helmet>
        <title>{t('homePage:title')}</title>
        <link rel='canonical' href='https://rentcarla.com/' />
      </Helmet>
      <SearchBarContainer $us={useCountry === 'US'}>
        <HeaderTitle>{t('homePage:header')}</HeaderTitle>
        <Search history={history} />
      </SearchBarContainer>
      <FeatureCards
        memorialDay={undefined}
        italy={undefined}
        isPageDynamic={undefined}
        data={undefined}
        spring={undefined}
      />
      {isAllowedCountry && <DownloadAppBanner />}
      {!hiddenByLang && <BlogSlide />}
      <FAQsHomePage />
      <Press />
      {/* Thanks Giving Event */}
      {isShowWheel && (
        <ReactPortal>
          <DesktopScreen>
            <>
              <ThanksgivingModal prizes={prizesData.prizes} />
            </>
          </DesktopScreen>
          <DefaultScreen>
            <>
              <ThanksgivingModalMobile prizes={prizesData.prizes} />
            </>
          </DefaultScreen>
        </ReactPortal>
      )}
    </Fragment>
  )
}

export default Home
