import { useQuery } from 'react-query'
import { fetchArticleBySlug } from './../sanity'
import ArticleMaps from './../util/blog/data'

const getArticleBySlugFunc = (slug) => {
  const legacyArticle = ArticleMaps[slug]

  if (legacyArticle) {
    legacyArticle.isLegacy = true // a flag to know it legacy blog
    return Promise.resolve(legacyArticle)
  }
  return fetchArticleBySlug(slug)
}

export function useArticlesBySlug(slug) {
  const { data, isLoading, error } = useQuery(['sanity-article', slug], () => getArticleBySlugFunc(slug))
  console.log({ data })
  return {
    data,
    isLoading,
    error
  }
}
