import { lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import routes from './routes'
import Loadable, { lazyWithRetry } from '@base/loadable'

// Dynamic landing page
const CountryArab = Loadable(
  lazyWithRetry(() => import('@routes/LandingPage/pages/CountryArab')),
  { ssr: true }
)
const BrandSixt = Loadable(
  lazyWithRetry(() => import('@routes/LandingPage/pages/BrandSixt')),
  { ssr: true }
)
const BrandKeddy = Loadable(
  lazyWithRetry(() => import('@routes/LandingPage/pages/BrandKeddy')),
  { ssr: true }
)
const BrandEuropcar = Loadable(
  lazyWithRetry(() => import('@routes/LandingPage/pages/BrandEuropcar')),
  { ssr: true }
)
const CityIstanbul = Loadable(
  lazyWithRetry(() => import('@routes/LandingPage/pages/CityIstanbul')),
  { ssr: true }
)
const MemorialDay = Loadable(
  lazyWithRetry(() => import('@routes/LandingPage/pages/MemorialDay')),
  { ssr: true }
)
const FourthOfJuly = Loadable(
  lazyWithRetry(() => import('@routes/LandingPage/pages/FourthOfJuly')),
  { ssr: true }
)
const LastMinute = Loadable(
  lazyWithRetry(() => import('@routes/LandingPage/pages/LastMinute')),
  { ssr: true }
)
const CityParis = Loadable(
  lazyWithRetry(() => import('@routes/LandingPage/pages/CityParis')),
  { ssr: true }
)
const CountryIceland = Loadable(
  lazyWithRetry(() => import('@routes/LandingPage/pages/CountryIceland')),
  { ssr: true }
)
const CoronaRental = Loadable(
  lazyWithRetry(() => import('@routes/LandingPage/pages/CoronaRental')),
  { ssr: true }
)
const ListYourFleet = Loadable(
  lazyWithRetry(() => import('@routes/ListYourFleet')),
  { ssr: true }
)
const BrandAdvantage = Loadable(
  lazyWithRetry(() => import('@routes/LandingPage/pages/BrandAdvantage')),
  { ssr: true }
)
// End Dynamic landing page

const Sitemap = Loadable(
  lazy(() => import('@routes/Sitemap')),
  { ssr: true }
)

import HomePage from './routes/Home'
const HotelPage = Loadable(
  lazy(() => import('@routes/HotelPage')),
  { ssr: true }
)
const BundlePage = Loadable(
  lazy(() => import('@routes/BundlePage')),
  { ssr: true }
)

//Redirected pages. It was originally made for skyscanner
import Redirect from './routes/Redirect'

const XO1 = Loadable(lazyWithRetry(() => import('@routes/XO1')))
const XO2 = Loadable(lazyWithRetry(() => import('@routes/XO2')))
const CarSearchResults = Loadable(lazyWithRetry(() => import('@routes/CarListing')))
const HotelXO2 = Loadable(lazy(() => import('@routes/XO2/hotel')))
const BundleXO2 = Loadable(lazy(() => import('@routes/XO2/bundle')))
const HotelListing = Loadable(lazy(() => import('@routes/Hotels')))
const BundleListing = Loadable(lazy(() => import('@routes/BundleList')))

const PageNotFound = Loadable(lazyWithRetry(() => import('@routes/PageNotFound')))
const TermsConditions = Loadable(
  lazyWithRetry(() => import('@routes/TermsConditions')),
  { ssr: true }
)
const Under25Help = Loadable(
  lazyWithRetry(async () => await import('@routes/Under25Help')),
  { ssr: true }
)
const PrivacyPolicy = Loadable(
  lazyWithRetry(() => import('@routes/PrivacyPolicy')),
  { ssr: true }
)
const Reservations = Loadable(
  lazyWithRetry(() => import('@routes/Reservations')),
  { ssr: true }
)
const Contact = Loadable(
  lazyWithRetry(() => import('@routes/ContactUs')),
  { ssr: true }
)
const Success = Loadable(lazyWithRetry(() => import('@routes/Success')))
const HotelReservationPage = Loadable(lazy(() => import('@routes/ReservationPage')))
const BundleReservationPage = Loadable(lazy(() => import('@routes/ReservationPage/Bundle')))

// const DuplicateBooking = Loadable(lazy(() => import('@components/DuplicateBooking')))

function Router() {
  return (
    <Switch>
      <Route exact key={'home'} path={'/'} component={HomePage} />
      <Route exact key={'hotel-home'} path={'/hotels'} component={HotelPage} />
      <Route exact key={'bundle-home'} path={'/hotel-bundle'} component={BundlePage} />
      <Route exact key={'under25help'} path={'/under25help'} component={Under25Help} />
      <Route exact key={'terms-conditions'} path={'/terms-conditions'} component={TermsConditions} />
      <Route exact key={'privacy-policy'} path={'/privacy-policy'} component={PrivacyPolicy} />
      <Route exact key={'xo1'} path={'/reservation'} component={XO1} />
      <Route
        exact
        key={'redirect'}
        path={[
          '/redirect/:departdatetime/:returndatetime/:pickup/:dropoff/:driverAge/:carId',
          '/redirect',
          '/redirect/:departdatetime/:returndatetime/:pickup/:dropoff/:driverAge/:carId/:carType'
        ]}
        component={Redirect}
      />
      <Route key={'xo2'} path={['/payment', '/my-reservations/payment']} component={XO2} />
      <Route exact key={'hotel-xo2'} path={'/hotel-payment'} component={HotelXO2} />
      <Route exact key={'bundle-xo2'} path={'/bundle-payment'} component={BundleXO2} />
      {/*<Route exact key={'mockXO2'} path={'/mock-payment'} component={MockXo2} />*/}
      <Route exact key={'success'} path={'/success'} component={Success} />
      <Route exact key={'my-reservations'} path={'/my-reservations'} component={Reservations} />
      <Route exact key={'contact'} path={'/contact'} component={Contact} />
      <Route exact key={'sitemap'} path={'/sitemap'} component={Sitemap} />
      {/* Landing pages */}
      <Route exact key={'arab-landing-page'} path={'/van-rental'} component={CountryArab} />
      {/*<Route exact key={'italy-landing-page'} path={"/italy-car-rental"} component={CountryItaly}/>*/}
      <Route exact key={'sixt-landing-page'} path={'/sixt-rent-a-car'} component={BrandSixt} />
      <Route exact key={'keddy-landing-page'} path={'/keddy-car-hire'} component={BrandKeddy} />
      <Route exact key={'europcar-landing-page'} path={'/europcar'} component={BrandEuropcar} />
      <Route exact key={'istanbul-landing-page'} path={'/istanbul-new-airport'} component={CityIstanbul} />
      <Route exact key={'memorial-day-weekend'} path={'/memorial-day-weekend'} component={MemorialDay} />
      <Route exact key={'4th-of-july'} path={'/4th-of-july'} component={FourthOfJuly} />
      <Route exact key={'last-minute-car-rental'} path={'/last-minute-car-rental'} component={LastMinute} />
      <Route exact key={'paris-car-rental'} path={'/paris-car-rental'} component={CityParis} />
      <Route exact key={'iceland-car-rental'} path={'/iceland-car-rental'} component={CountryIceland} />
      <Route exact key={'coronavirus-car-rental'} path={'/coronavirus-car-rental'} component={CoronaRental} />
      <Route exact key={'list-your-fleet'} path={'/listyourfleet'} component={ListYourFleet} />
      <Route exact key={'advantage-landing-page'} path={'/advantage-rent-a-car'} component={BrandAdvantage} />
      {/* End landing pages */}
      <Route
        exact
        key={'rental-cars'}
        path={'/rental-cars/:pickupLocationId/:dropOffLocationId/:pickupDateStr/:dropOffDateStr'}
        component={CarSearchResults}
      />
      <Route
        exact
        key={'hotel-results'}
        path={[
          '/hotel-results/:latitude/:longitude/:pickupDateStr/:dropOffDateStr',
          '/hotel-results/:latitude/:longitude/:pickupDateStr/:dropOffDateStr/view'
        ]}
        component={HotelListing}
      />
      <Route
        exact
        key={'bundle-list'}
        path={['/hotel-bundle/list', '/hotel-bundle/list/view']}
        component={BundleListing}
      />
      <Route exact key={'hotel-reservation'} path={'/hotel-reservation'} component={HotelReservationPage} />
      <Route exact key={'bundle-reservation'} path={'/bundle-reservation'} component={BundleReservationPage} />
      {routes
        .filter((r) => r.component)
        .map((route) => {
          if (route.id === 'blog-post' || route.id === 'blog') {
            return (
              <Route
                key={route.id}
                {...route}
                render={({ staticContext }) => {
                  if (staticContext) {
                    staticContext.statusCode = 200
                  }

                  return route.component
                }}
              />
            )
          }
          return <Route key={route.id} {...route} />
        })}
      <Route exact key={'fallback'} path={'*'} component={PageNotFound} />
    </Switch>
  )
}

export default Router
