import React, { Component, lazy } from 'react'
import { Provider } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { Helmet } from 'react-helmet'
import debug from 'debug'
import util from '@util/index'
import { theme } from '@base/styles'
import SessionManager from '@services/SessionManager'
import ErrorHandler from '@services/ErrorHandler'
import { init as initNotification } from '@services/Notification'
import { PageMainContainer } from './components/layout'
import EventSender from '@services/EventSender'
import ConnectedFooter from '@util/ConnectedFooter'
import Router from '@base/Router'
import loadBodyScript from '@base/3nd-party'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import * as _ from 'lodash-es'
import ScrollToTop from '@components/ScrollTop'
import { GrowthBookRootProvider } from '@base/growthbook'
import { store as reduxStore } from '@redux/store'
import { withSSR } from 'react-i18next'
import Loadable from '@base/loadable'
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import NavigationBar from '@base/layout/Nav'
import injectSardineScript from './3nd-party/sardine'
import injectAffirmScript from './3nd-party/affirm'

const DownloadPopup = Loadable(lazy(() => import('@components/DownloadPopup')))
const ReactPortal = Loadable(lazy(() => import('@components/ReactPortal')))
const DefaultErrorModal = Loadable(lazy(() => import('@components/Modal')))
const ReactToastify = Loadable(lazy(() => import('@components/common/ReactToastify')))
const PromoCode = Loadable(lazy(() => import('@components/PromoCodeModal')))
const GATracker = Loadable(lazy(() => import('@services/GATracker')))

// old breakpoints: [576, 1200]
// TODO: move to theme.ts
const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 1024,
      lg: 1280,
      xl: 1536
    }
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true
      }
    }
  },
  palette: {
    primary: {
      main: '#FF7D0E'
    },
    text: {
      primary: '#525266',
      secondary: '#696984'
    }
  },
  typography: {
    fontFamily: 'Quicksand, Quicksand Regular, sans-serif'
  }
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60
    }
  }
})

const log = debug('carla:app')
// TODO: review and remove
export const CarlaContext = React.createContext({})

const DefaultHeaders = (
  <Helmet>
    <title>Carla - Rent a car from Avis, Hertz, Enterprise, Dollar, Keddy. Car rental app</title>
    <meta
      name='description'
      content="Compare car rental prices from 900 companies in 160 countries. Get car rental offers from major and local suppliers in seconds. Select the best rental car fits your needs. Complete your car reservation under 30 seconds. Whether you're planning a road trip, a family vacation, or a business trip, finding the right rental car is easy with our comprehensive search engine. With a wide selection of cars and rental options to choose from, you'll find the perfect vehicle for your needs. Plus, our streamlined booking process makes it quick and easy to reserve your rental car in just a few clicks.
    "
    />
    <meta name='msvalidate.01' content='D638AAF25CECF1FAD26EB51419027073' />
    <meta name='yandex-verification' content={util.getYandexContent()} />
    <meta property='fb:app_id' content='1508686502763666' />
    <meta name='twitter:card' content='summary_large_image' />
    <meta name='twitter:site' content='@RentCarla' />
    <meta name='twitter:creator' content='@RentCarla' />
    <meta name='apple-itunes-app' content='app-clip-bundle-id=com.kasaba.Carla.Clip, app-id=1035631234' />
    <meta name='google' value='notranslate'></meta>
    <script type='application/ld+json'>{util.getOrganizationStructure()}</script>
    <script type='application/ld+json'>{util.getWebsiteStructure()}</script>
    <link rel='preconnect' href='https://www.google-analytics.com' />
  </Helmet>
)

class App extends Component {
  state = {
    error: null,
    context: {
      history: null,
      location: null
    }
  }

  initClarity = () => {
    ;(function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          ;(c[a].q = c[a].q || []).push(arguments)
        }
      t = l.createElement(r)
      t.async = 1
      t.src = 'https://www.clarity.ms/tag/' + i
      y = l.getElementsByTagName(r)[0]
      y.parentNode.insertBefore(t, y)
    })(window, document, 'clarity', 'script', 'mjhglqefmo')
  }

  constructor(props) {
    super(props)
    // debug module browser setting
    if (util.isDevelopment() && util.isBrowser() && util.isCookiesEnabled()) {
      localStorage.debug = 'carla:*'
      // tool to do various operations
      window.dev = util.dev
    }

    log(
      `starting carla app, environment: "${import.meta.env.VITE_CARLA_ENV}", version: "${
        import.meta.env.VITE_CARLA_VERSION
      }", session id: ${SessionManager.getSessionId()}`
    )

    if (util.isProduction()) ErrorHandler.init()
    // used by optimize
    if (util.isBrowser()) {
      window.store = reduxStore
    }

    // set global context provider values
    this.state.context = {
      history: props.history,
      location: props.location
    }
  }

  // redirect to home page when user closes error modal
  handleAfterError = () => {
    this.setState({ error: null })
    this.props.history.push('/')
  }

  componentDidMount() {
    // set device id and user country
    SessionManager.startSession()
    // send session end event when closing tab
    window.addEventListener('beforeunload', () => {
      EventSender.sessionEnded()
    })

    if (util.isProduction()) this.initClarity()

    // Load onesignal script
    loadBodyScript({
      key: 'oneSignalScript',
      src: 'https://cdn.onesignal.com/sdks/OneSignalSDK.js',
      isAsync: true,
      callback: initNotification
    })

    loadBodyScript({
      key: 'googlePayScript',
      src: 'https://pay.google.com/gp/p/js/pay.js',
      isAsync: true
    })

    loadBodyScript({
      key: 'stripeScript',
      src: 'https://js.stripe.com/v2/',
      isAsync: true
    })

    injectAffirmScript()

    fetch('/carlin/sardineSessionKey', { headers: { 'X-Requested-With': 'Fetch' } })
      .then((res) => res.json())
      .then((data) => {
        injectSardineScript(
          import.meta.env.MODE === 'development',
          import.meta.env.VITE_SARDINE_CLIENT_ID,
          data.sessionKey,
          SessionManager.getCarlinDeviceId()
        )
      })
  }

  // updaet propogating context (route locaion)
  componentDidUpdate(prevProps, prevState) {
    const isLocationChanged = prevProps.location !== this.props.location

    const newContext = _.clone(this.state.context)

    if (isLocationChanged) {
      newContext.location = this.props.location
      this.setState({ context: newContext })
    }
  }

  /**
   * show "something is wrong" and send error to sentry
   */
  componentDidCatch(error, errorInfo) {
    ErrorHandler.handleReactError(error, errorInfo)
    this.setState({ error })
  }

  render() {
    return (
      <Provider store={reduxStore}>
        <QueryClientProvider client={queryClient}>
          <MUIThemeProvider theme={muiTheme}>
            <GrowthBookRootProvider>
              {/* TODO: review and remove context */}
              <CarlaContext.Provider value={this.state.context}>
                <ThemeProvider theme={theme}>
                  {DefaultHeaders}
                  <NavigationBar />
                  <PageMainContainer>
                    {!_.isEmpty(this.state.error) && (
                      <DefaultErrorModal isOpen={true} onClose={this.handleAfterError} closable={true} ok={true} />
                    )}
                    {!this.state.error && <Router />}
                  </PageMainContainer>
                  <ConnectedFooter />
                  <GATracker />
                  <PromoCode />
                  <ReactPortal>
                    <DownloadPopup />
                  </ReactPortal>
                  <ReactToastify />
                </ThemeProvider>
              </CarlaContext.Provider>
            </GrowthBookRootProvider>
          </MUIThemeProvider>
          <ScrollToTop />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    )
  }
}

const AppWithRouter = withRouter(App)
const FinalApp = withSSR()(AppWithRouter)

export default FinalApp
