import React from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { BlueGreyLink, Container, El } from '../../components/layout'
import util from '../../util'
import { BlogCard, BlogList } from './index'
import { ArticleContent } from './article.styles'
import { useArticlesBySlug } from '../../hooks/useArticlesBySlug'
import { getFromCache } from '../../sanity/cache'
import useCurrentLang from '../../hooks/useCurrentLang'
import * as _ from 'lodash-es'

import Loadable from '@base/loadable'
const PageNotFound = Loadable(React.lazy(() => import('@routes/PageNotFound')))
const PortableText = Loadable(
  React.lazy(() => import('@components/PortableText/PortableText')),
  { ssr: true }
)
const SocialShare = Loadable(React.lazy(() => import('@routes/Blog/SocialShare')))
const BlogAuthor = Loadable(React.lazy(() => import('@components/BlogAuthor')))
const DownloadAppSection = Loadable(React.lazy(() => import('@components/DownloadAppSection')))
const RecentArticles = Loadable(React.lazy(() => import('./RecentArticles')))

const articleMap = util.getArticleMap()

function Article({ match, staticContext = {}, ...rest }) {
  const currentLang = useCurrentLang() || 'en'
  const slug = match.params.slug
  let article = null

  const { data, isLoading } = useArticlesBySlug(slug, staticContext)

  if (staticContext && staticContext.data) {
    // Server
    if (!_.isEmpty(staticContext.data[currentLang])) {
      article = staticContext.data[currentLang]
    } else {
      article = !_.isEmpty(staticContext.data['en']) ? staticContext.data['en'] : staticContext.data
    }
  } else {
    if (data) {
      if (data.isLegacy) {
        // Legacy content (only en)
        article = data
      } else {
        // Sanity content map by lang
        article = data[currentLang] ? data[currentLang] : data['en']
      }
    }
  }

  if (_.isEmpty(article) && isLoading) return null
  if (_.isEmpty(article) && !isLoading) return <PageNotFound />

  return (
    <El bg='white' {...rest}>
      <Helmet>
        <script type='application/ld+json'>{util.getArticleStructure(article, false)}</script>
        <script type='application/ld+json'>{util.getBreadCrumbStructure(article, false)}</script>
        <title>{article.browserTitle || article.title}</title>
        <link rel='amphtml' href={article.ampUrl} />
        <link rel='canonical' href={article.canonicalUrl} />
        <meta name='description' content={article.description} />
        <meta name='keywords' content={article.keywords} />
        <meta property='og:url' content={article.canonicalUrl} />
        <meta property='og:type' content='article' />
        <meta property='og:title' content={article.title} />
        <meta property='og:description' content={article.description} />
        <meta property='og:image' content={article.imageUrl} />
        <meta property='og:image:width' content={article.imageWidth} />
        <meta property='og:image:height' content={article.imageHeight} />
        <meta name='twitter:title' content={article.title} />
        <meta name='twitter:description' content={article.description} />
        <meta name='twitter:image' content={article.imageUrl} />
      </Helmet>
      {!article.isSanityContent ? (
        <>
          <ArticleContent dangerouslySetInnerHTML={{ __html: article?.getContent(util.formatCanonicalContent) }} />
          {article.article && <BlogAuthor content={article.article} />}
        </>
      ) : (
        <>
          <ArticleContent>
            <PortableText {...article} />
          </ArticleContent>
          {article.authors && <BlogAuthor content={article.authors} isSanityContent={true} />}
        </>
      )}
      <SocialShare url={article.canonicalUrl} />
      <RecentArticles slug={slug} />
      <DownloadAppSection />
    </El>
  )
}

Article.propTypes = {}

Article.load = async (match) => {
  try {
    const slug = match.params.slug
    const staticArticle = articleMap[slug]
    if (!_.isEmpty(staticArticle)) {
      return staticArticle
    }
    return await getFromCache(slug)
  } catch (error) {
    console.error(error)
  }
}

export default Article
